<template>
  <div class="project-person-remove">
    <Modal v-model="visible" @on-cancel="returnPath" :mask-closable="false" width="360">
      <p slot="header" style="color:#f60;text-align:center">
        <Icon type="information-circled"></Icon>
        <span>解除管理人员关联</span>
      </p>
      <div style="text-align:center">
        <p>确认解除该人员与项目关联么？</p>
      </div>
      <div slot="footer">
        <Button type="error" size="large" long @click="removePerson">解除</Button>
      </div>
    </Modal>
  </div>
</template>

<script>
export default {
  name: 'project-person-remove',
  props: ['data', 'show', 'projectId'],
  data () {
    return {
      visible: false
    }
  },
  mounted () {
  },
  watch: {
    show: function (newVal) {
      this.visible = newVal
    }
  },
  methods: {
    removePerson () {
      this.submitEnabled = false
      this.$Spin.show()
      this.$Api.Project.removeProjectUser({
        UserId: this.data.Id,
        IdNumber: this.data.IdNumber,
        Tel: this.data.MobilePhoneNumber,
        Permission: 0,    // 无所谓
        ProjectId: this.projectId
      }).then(() => {
        this.$Spin.hide()
        this.submitEnabled = true
        this.$Message.success('操作成功！')
        this.returnPath()
      })
      .catch(err => {
        this.$Spin.hide()
        this.submitEnabled = true
        this.$Notice.error({
          title: '操作失败!',
          desc: err.message
        })
      })
    },
    returnPath () {
      this.$emit('close')
    },
    refreshList () {
      this.$emit('close')
    }
  }
}
</script>

<style scoped>
</style>
